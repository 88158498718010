/* src/App.css */
* {
  box-sizing: border-box;
}

body {
  background: url('./bg_pm.webp') no-repeat center center fixed;
  background-size: cover;
}

.app-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 1400px;
    margin: auto;
  }
  
  .ai-video-container {
    text-align: center;
  }
  
  .ai-video {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border: 2px solid #333;
    border-radius: 8px;
  }
  
  .prompt-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .prompt-input {
    width: 70%;
    padding: 10px;
    font-size: 1rem;
    margin-right: 10px;
  }
  
  .prompt-button {
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .prompt-button:disabled {
    background: #ccc;      /* A grey background to indicate disabled state */
    opacity: 0.6;          /* Lower opacity for a faded look */
    cursor: not-allowed;   /* Change the cursor to indicate it isn’t clickable */
    pointer-events: none;  /* Ensure no mouse events are processed */
  }
  
  .prompt-button:disabled:active {
    transform: none;       /* Prevent any active/pressed transform effects */
    box-shadow: none;      /* Remove any shadow that might appear on press */
  }
  
  .status-container {
    margin-top: 20px;
  }
  
  .status-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .status-container li {
    background: #f0f0f0;
    padding: 5px 10px;
    margin: 5px 0;
    border-radius: 4px;
  }
  
  .content-display {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 20px;
  }
  
  .meditation-text {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .meditation-media {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .meditation-video,
  .meditation-audio {
    width: 100%;
  }
  
  .chat-dialog {
    margin-top: 30px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
  }
  
  .chat-messages {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    background: #fafafa;
    margin-bottom: 10px;
  }
  
  .chat-message {
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 4px;
  }
  
  .chat-message.ai {
    background: #e0f7fa;
    text-align: left;
  }
  
  .chat-message.user {
    background: #c8e6c9;
    text-align: right;
  }
  
  .chat-input-container {
    display: flex;
    gap: 10px;
  }
  
  .chat-input {
    flex: 1;
    padding: 10px;
    font-size: 1rem;
  }
  
  .chat-send-button {
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
  }

    /* Container that holds the button, slider, and dropdown */
  .action-group {
    /* by default, div is block-level, which will expand to 100% of its parent */
    /* but if you want the exact width of the button, you can use inline-block: */
    display: inline-block;
    text-align: left; /* or center, if you like */
    vertical-align: top; /* aligns the top of the button with the top of the slider */
  }

  /* Ensure the button, slider, and dropdown stack vertically inside .action-group */
  .action-group > * {
    display: block;
  }

  /* Let the slider and dropdown fill 100% of the .action-group width */
  .volume-slider input[type="range"],
  .voice-selection select {
    width: 100%;
  }

  /*margin: "1em 0"*/
  .volume-slider {
    width: 80%;
    margin: 10px auto;
    text-align: right;
  }

  .voice-selection {
    width: 80%;
    margin: 10px auto;
    text-align: right;
  }

  .fullscreen-video-wrapper:fullscreen {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .fullscreen-video-wrapper:-webkit-full-screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .fullscreen-video-wrapper:fullscreen > * {
    width: 100%;
    height: 100%;
  }
  
  .fullscreen-video-wrapper:-webkit-full-screen > * {
    width: 100%;
    height: 100%;
  }

  /* When the screen is in portrait mode or has a low aspect ratio (height > width by 10% or more) */
@media screen and (max-aspect-ratio: 9/10), screen and (orientation: portrait) {
  /* Change the container that holds your main elements to a column layout */
  .media-container {
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
    justify-content: flex-start;
  }
  
  /* Ensure the video container spans the full width */
  .ai-video-container {
    flex: 1 0 auto; 
    width: 100%;
  }
  
  /* Adjust the prompt container to take full width as well */
  .prompt-container {
    width: 100%;
    margin: 10px auto;
    text-align: center;
  }
  
  /* Similarly, the meditation container should span full width */
  .meditation-container {
    flex: 1 0 auto;
    width: 100%;
  }
  
  /* Optionally, if you want the video to adjust its height/width,
     you can override the video styles too */
  .ai-video {
    width: 100%;
    height: auto;
    max-height: none;
  }
}